/**
 * Const with params element
 * Block testimonial text component
 * Display a block of testimonial text
 */
/* Import section */
import React from "react"
import "../styles/components/blocktestimonialtext.scss"

/* Declaration function */
const Blocktestimonialtext = ( { keyTestimonial, authorTestimonial, descTestimonial, classLayout} ) => {
  return (
    <div key={keyTestimonial} className={`block-testimonial-text ${classLayout}`}>
      <div className="wrap-testimonial-text">
        <div className={`separator-quote ${classLayout}`}></div>
        <blockquote className="content-testimonial-text">
          <div className={classLayout} dangerouslySetInnerHTML={{ __html:descTestimonial}} />
        </blockquote>
        <h2 className="author-testimonial-text">- {authorTestimonial}</h2>
      </div>
    </div>
  )
}

/* Export function */
export default Blocktestimonialtext

