/**
 * Const with params element
 * Block testimonial video checklist component
 * Display a block of testimonial video with checklist
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Boxctavideo from "../components/boxctavideo"
import Checklist from "../components/checklist"
import "../styles/components/blocktestimonialvideochecklist.scss"

/* Declaration function */
const Blocktestimonialvideochecklist = ( { keyTestimonial, titleTestimonial, subTitleTestimonial, arrChecklistTestimonial, videoTestimonial, visualTestimonial} ) => {

  let arrItemsChecklist = []
  arrChecklistTestimonial.map((item) =>
    arrItemsChecklist.push({textChecklist: item.itemTestimonialChecklist})
  )

  return (
    <div key={keyTestimonial} className="block-testimonial-videochecklist">
      <div className="wrap-title-visual-testimonial">
        <div className="title-testimonial">
          <h2>{titleTestimonial}</h2>
          <Boxctavideo text_cta="Watch Video" index={keyTestimonial} video_title={titleTestimonial} video_url={videoTestimonial} classbtn_video="square" classbtn_color="white" />
        </div>
        <div className="visual-testimonial">
          <GatsbyImage image={visualTestimonial} alt={`Hello Health's US testimonial: ${titleTestimonial}`} />
        </div>
      </div>
      <div className="wrap-checklist-testimonial">
        <div className="content-checklist-testimonial">
          <h3>{subTitleTestimonial}</h3>
          <Checklist arrItems={arrItemsChecklist} />
        </div>
      </div>
    </div>
  )
}

/* Export function */
export default Blocktestimonialvideochecklist

