/**
 * Const with params element
 * Block testimonial video text component
 * Display a block of testimonial video with text
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Boxctavideo from "../components/boxctavideo"
import "../styles/components/blocktestimonialvideotext.scss"

/* Declaration function */
const Blocktestimonialvideotext = ( { keyTestimonial, titleTestimonial, descTestimonial, videoTestimonial, visualTestimonial, layout, classLayout} ) => {
  return (
    <div key={keyTestimonial} className="block-testimonial-videotext">
      {layout === "vertical" ? (
        <div className={`wrap-testimonial vertical-testimonial ${classLayout}`}>
          <div className="content-testimonial">
            <h2>{titleTestimonial}</h2>
            <div className="desc-testimonial" dangerouslySetInnerHTML={{ __html:descTestimonial}} />
            <Boxctavideo index={keyTestimonial} text_cta="Watch Video" video_title={titleTestimonial} video_url={videoTestimonial} classbtn_video="square" classbtn_color="white" />
          </div>
          {visualTestimonial &&
          <div className="visual-testimonial">
            <GatsbyImage image={visualTestimonial} alt={`Hello Health's US testimonial: ${titleTestimonial}`} />
          </div>
          }
        </div>
      ) : (
        <div className="wrap-testimonial horizontal-testimonial">
          <div className="content-testimonial">
            <h2>{titleTestimonial}</h2>
            <div className="desc-testimonial" dangerouslySetInnerHTML={{ __html:descTestimonial}} />
          </div>
          <div className="visual-testimonial">
            <GatsbyImage image={visualTestimonial} alt={`Hello Health's US testimonial: ${titleTestimonial}`} />
            <Boxctavideo index={keyTestimonial} text_cta="Watch Video" video_title={titleTestimonial} video_url={videoTestimonial} classbtn_video="square" classbtn_color="white" />
          </div>
        </div>
      )}

    </div>
  )
}

/* Export function */
export default Blocktestimonialvideotext

