/**
 * Testimonials page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Blocktestimonialtext from "../components/blocktestimonialtext"
import Blocktestimonialvideochecklist from "../components/blocktestimonialvideochecklist"
import Blocktestimonialvideotext from "../components/blocktestimonialvideotext"
import Herotype5 from "../components/herotype5"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/testimonials.scss"

/* Page function declaration */
const Testimonialspage = ({data}) => {

  ///Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageTestimonials

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroTestimonials.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroTestimonials})
  ))

  //Array for testimonials list
  const dataTestimonials = data.allWpTestimonial
  var arrTestimonialVideoChecklist = dataTestimonials.nodes.filter(function (element) {
    return element.catstestimonials.nodes[0].slug === "video-checklist"
  })

  var arrTestimonialVideoText = dataTestimonials.nodes.filter(function (element) {
    return element.catstestimonials.nodes[0].slug === "video-text"
  })

  var arrTestimonialText = dataTestimonials.nodes.filter(function (element) {
    return element.catstestimonials.nodes[0].slug === "text"
  })

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "getstarted3"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-testimonials top-section">
        <Herotype5 h1title={dataAcf.titleHeroTestimonials} arrIntro={arrTextHero} colorBar="white" />
      </section>

      {/*Testimonials details section */}
      <section className="section-details-testimonials">
        <div className="wrap-intro-details-testimonials wrap-maincontent">
          <div className="full-row">
            <Blocktestimonialvideochecklist
              keyTestimonial="testimonial-checklist-0"
              titleTestimonial={arrTestimonialVideoChecklist[0].title}
              subTitleTestimonial={arrTestimonialVideoChecklist[0].acfPostTestimonials.titleH3TestimonialChecklist}
              arrChecklistTestimonial={arrTestimonialVideoChecklist[0].acfPostTestimonials.repeaterTestimonialChecklist}
              videoTestimonial={arrTestimonialVideoChecklist[0].acfPostTestimonials.videoLinkTestimonial}
              visualTestimonial={arrTestimonialVideoChecklist[0].acfPostTestimonials.imageTestimonial.localFile.childImageSharp.gatsbyImageData}
            />
          </div>

          <div className="half-row">
            <Blocktestimonialtext
              keyTestimonial="testimonial-text-1"
              authorTestimonial={arrTestimonialText[1].acfPostTestimonials.authorTestimonial}
              descTestimonial={arrTestimonialText[1].acfPostTestimonials.contentTestimonial}
              classLayout="half-col"
            />
            <Blocktestimonialvideotext
              keyTestimonial="testimonial-videotext-4"
              titleTestimonial={arrTestimonialVideoText[4].title}
              descTestimonial={arrTestimonialVideoText[4].acfPostTestimonials.contentTestimonial}
              videoTestimonial={arrTestimonialVideoText[4].acfPostTestimonials.videoLinkTestimonial}
              layout="vertical"
              classLayout="no-picture"
            />
          </div>

          <div className="full-row">
            <Blocktestimonialvideotext
              keyTestimonial="testimonial-videotext-0"
              titleTestimonial={arrTestimonialVideoText[0].title}
              descTestimonial={arrTestimonialVideoText[0].acfPostTestimonials.contentTestimonial}
              videoTestimonial={arrTestimonialVideoText[0].acfPostTestimonials.videoLinkTestimonial}
              visualTestimonial={arrTestimonialVideoText[0].acfPostTestimonials.imageTestimonial.localFile.childImageSharp.gatsbyImageData}
              layout="horizontal"
            />
          </div>

          <div className="full-row">
            <Blocktestimonialtext
              keyTestimonial="testimonial-text-0"
              authorTestimonial={arrTestimonialText[0].acfPostTestimonials.authorTestimonial}
              descTestimonial={arrTestimonialText[0].acfPostTestimonials.contentTestimonial}
              classLayout="full-col"
            />
          </div>

          <div className="half-row">
            <Blocktestimonialvideotext
              keyTestimonial="testimonial-videotext-1"
              titleTestimonial={arrTestimonialVideoText[1].title}
              descTestimonial={arrTestimonialVideoText[1].acfPostTestimonials.contentTestimonial}
              videoTestimonial={arrTestimonialVideoText[1].acfPostTestimonials.videoLinkTestimonial}
              visualTestimonial={arrTestimonialVideoText[1].acfPostTestimonials.imageTestimonial.localFile.childImageSharp.gatsbyImageData}
              layout="vertical"
            />

            <Blocktestimonialvideotext
              keyTestimonial="testimonial-videotext-2"
              titleTestimonial={arrTestimonialVideoText[2].title}
              descTestimonial={arrTestimonialVideoText[2].acfPostTestimonials.contentTestimonial}
              videoTestimonial={arrTestimonialVideoText[2].acfPostTestimonials.videoLinkTestimonial}
              visualTestimonial={arrTestimonialVideoText[2].acfPostTestimonials.imageTestimonial.localFile.childImageSharp.gatsbyImageData}
              layout="vertical"
              classLayout="reverse"
            />
          </div>

          <div className="full-row">
            <Blocktestimonialvideochecklist
              keyTestimonial="testimonial-checklist-1"
              titleTestimonial={arrTestimonialVideoChecklist[1].title}
              subTitleTestimonial={arrTestimonialVideoChecklist[1].acfPostTestimonials.titleH3TestimonialChecklist}
              arrChecklistTestimonial={arrTestimonialVideoChecklist[1].acfPostTestimonials.repeaterTestimonialChecklist}
              videoTestimonial={arrTestimonialVideoChecklist[1].acfPostTestimonials.videoLinkTestimonial}
              visualTestimonial={arrTestimonialVideoChecklist[1].acfPostTestimonials.imageTestimonial.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </section>


      {/* Banner CTA Plans */}
      <Bannerwithcta
        classBanner="section-banner-testimonials"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "testimonials"}) {
      acfPageTestimonials {
        repeaterHeroTestimonials {
          introHeroTestimonials
        }
        titleHeroTestimonials
      }
      title
      seo {
        title
        metaDesc
      }
    }
    allWpTestimonial {
      nodes {
        title
        catstestimonials {
          nodes {
            name
            slug
          }
        }
        acfPostTestimonials {
          authorTestimonial
          contentTestimonial
          repeaterTestimonialChecklist {
            itemTestimonialChecklist
          }
          titleH3TestimonialChecklist
          videoLinkTestimonial
          imageTestimonial {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 800)
              }
            }
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
/* Export page informations */
export default Testimonialspage
