/**
 * Const with params element
 * Hero type5 component
 * Display the hero type 5: h1 + slider at right
 */
/* Import section */
import React from "react"
import Sliderhero from "../components/sliderhero"
import "../styles/components/herotype5.scss"

/* Declaration function */
const Herotype5 = ( { keyHero, h1title, arrIntro, colorBar} ) => {
  return (
      <div className="wrap-hero-type5">
        <div className="wrap-title">
        <h1 dangerouslySetInnerHTML={{__html:h1title}} />
        </div>
        <div className="wrap-box-slider">
          <div className="box-slider">
            <div className={`separator-bar ${colorBar}`}></div>
            <Sliderhero keySlider={keyHero} arrText={arrIntro} classSlider="slider-hero-type5" />
          </div>
        </div>
      </div>
  )
}

/* Export function */
export default Herotype5

